@tailwind base;
@tailwind components;
@tailwind utilities;

/* colors paddings for all components use this consistently everywhere */
/* Light & Medium Blue Palette */
/* Modern Light & Medium Blue Palette */
:root {
  /* Primary Colors (60%) */
  --primary-50: #f5f7ff;
  --primary-100: #e4e9ff;
  --primary-200: #d1d9ff;
  --primary-300: #a6b5ff;
  --primary-400: #7b8fff;
  --primary-500: #4c66ff;
  --primary-600: #3b4fd9;
  --primary-700: #0055b6;

  /* Secondary Colors (30%) */
  --secondary-50: #f8f9fa;
  --secondary-100: #edf0f2;
  --secondary-200: #e2e6ea;
  --secondary-300: #dde2e7;
  --secondary-400: #ced4da;
  --secondary-500: #adb5bd;

  /* Accent Colors (10%) */
  --accent-50: #fff8f1;
  --accent-100: #ffe9d4;
  --accent-200: #ffd4a8;
  --accent-300: #ffba70;
  --accent-400: #ff9838;
  --accent-500: #ff7b00;

  /* Background Colors */
  --bg-50: #f8fafc;
  --bg-100: #f1f5f9;
  --bg-200: #e2e8f0;
  --bg-300: #cbd5e1;

  /* Text Colors */
  --text-50: #f8fafc;
  --text-100: #1e293b;
  --text-200: #475569;
  --text-300: #64748b;

  /* Success Colors */
  --success-50: #f0fdf4;
  --success-100: #dcfce7;
  --success-500: #22c55e;

  /* Standard Spacing */
  --space-xs: 0.25rem;  /* 4px */
  --space-sm: 0.5rem;   /* 8px */
  --space-md: 1rem;     /* 16px */
  --space-lg: 1.5rem;   /* 24px */
  --space-xl: 2rem;     /* 32px */

  /* Card & Container Styles */
  --card-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --card-shadow-hover: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --border-radius: 0.5rem;

  /* Neutral Colors (30%) */
  --gray-50: #f8fafc;   /* Background alternative */
  --gray-100: #f1f5f9;  /* Subtle backgrounds */
  --gray-200: #e2e8f0;  /* Borders, dividers */
  --gray-300: #cbd5e1;  /* Disabled states */
  --gray-400: #94a3b8;  /* Placeholder text */
  --gray-500: #64748b;  /* Secondary text */
  --gray-600: #475569;  /* Primary text */
  --gray-700: #334155;  /* Headings */
  --gray-800: #1e293b;  /* High emphasis text */
  --gray-900: #0f172a;  /* Highest contrast */

  /* Semantic Colors (10%) */
  --warning: #f59e0b;   /* Warning states */
  --error: #ef4444;     /* Error states */
  --info: #06b6d4;      /* Info states */

  /* Functional Color Assignments */
  
  /* Backgrounds */
  --bg-primary: var(--primary-50);      /* Main background */
  --bg-secondary: var(--primary-100);   /* Secondary background */
  --bg-tertiary: var(--bg-50);     /* Tertiary background */
  --bg-elevated: #ffffff;            /* Elevated elements */
  
  /* Text Colors */
  --text-primary: var(--text-100);   /* Primary text */
  --text-secondary: var(--text-200); /* Secondary text */
  --text-tertiary: var(--text-300);  /* Tertiary text */
  --text-inverse: #ffffff;           /* Text on dark backgrounds */
  
  /* Interactive Elements */
  --action-primary: var(--primary-500);    /* Primary buttons */
  --action-primary-hover: var(--primary-600); /* Primary hover */
  --action-secondary: var(--primary-400);   /* Secondary buttons */
  --action-secondary-hover: var(--primary-500); /* Secondary hover */
  
  /* Borders & Dividers */
  --border-light: var(--gray-200);    /* Light borders */
  --border-medium: var(--gray-300);   /* Medium borders */
  --border-heavy: var(--gray-400);    /* Heavy borders */
  
  /* Header & Navigation */
  --header-bg: var(--primary-700);       /* Header background */
  --header-text: #ffffff;             /* Header text */
  --nav-bg: var(--primary-600);         /* Navigation background */
  --nav-text: #ffffff;               /* Navigation text */
  
  /* Badges & Tags */
  --badge-bg-blue: var(--primary-100);   /* Blue badge background */
  --badge-text-blue: var(--primary-700); /* Blue badge text */
  --badge-bg-gray: var(--gray-100);   /* Gray badge background */
  --badge-text-gray: var(--gray-700); /* Gray badge text */
  
  /* Shadows */
  --shadow-sm: 0 1px 2px rgba(0, 0, 0, 0.05);
  --shadow-md: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  --shadow-lg: 0 10px 15px -3px rgba(0, 0, 0, 0.1);

  /* Primary gradient colors */
  --gradient-start: #4F46E5;  /* Primary indigo */
  --gradient-middle: #6366F1;  /* Secondary indigo */
  --gradient-end: #818CF8;    /* Lighter indigo */
}

/* Global Styles */
body {
  background-color: var(--bg-primary);
  color: var(--text-primary);
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

/* Section Background */
.section {
  background-color: var(--bg-secondary);
  padding: 20px;
  margin: 20px 0;
  border-radius: 8px;
}

/* Headers and Navigation */
header {
  background-color: var(--header-bg);
  color: var(--header-text);
  padding: 15px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

header h1, header nav a {
  color: var(--header-text);
  text-decoration: none;
}

/* Primary Button Styles */
.button-primary {
  background-color: var(--action-primary);
  color: var(--text-inverse);
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
  transition: all 0.2s ease;
  box-shadow: var(--shadow-sm);
}

.button-primary:hover {
  background-color: var(--action-primary-hover);
  transform: translateY(-1px);
  box-shadow: var(--shadow-md);
}

/* Secondary Button/Link Styles */
.button-secondary {
  background-color: var(--action-secondary);
  color: var(--text-inverse);
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
  transition: all 0.2s ease;
  box-shadow: var(--shadow-sm);
}

.button-secondary:hover {
  background-color: var(--action-secondary-hover);
  transform: translateY(-1px);
  box-shadow: var(--shadow-md);
}

/* Badge/Accent Styles */
.badge {
  padding: 0.25rem 0.75rem;
  border-radius: 9999px;
  font-size: 0.875rem;
  font-weight: 500;
}

.badge-blue {
  background-color: var(--badge-bg-blue);
  color: var(--badge-text-blue);
}

.badge-gray {
  background-color: var(--badge-bg-gray);
  color: var(--badge-text-gray);
}

/* Utility Classes */
.text-secondary {
  color: var(--text-secondary);
}

@layer components {
  /* Card Styles */
  .card-hover {
    @apply hover:border-[var(--primary-200)] hover:shadow-md;
  }

  /* Button Styles */
  .btn-primary {
    @apply bg-[var(--primary-500)] text-white px-4 py-2 rounded-md hover:bg-[var(--primary-600)] transition-colors duration-200;
  }

  .btn-secondary {
    @apply bg-[var(--bg-100)] text-[var(--text-200)] px-4 py-2 rounded-md hover:bg-[var(--bg-200)] transition-colors duration-200;
  }

  /* Badge Styles */
  .badge-primary {
    @apply inline-flex items-center rounded-full bg-[var(--primary-100)] px-2.5 py-0.5 text-xs font-medium text-[var(--primary-600)];
  }

  .badge-success {
    @apply inline-flex items-center rounded-full bg-[var(--success-100)] px-2.5 py-0.5 text-xs font-medium text-[var(--success-500)];
  }

  /* Card Container Styles */
  .card-container {
    @apply bg-[var(--bg-100)] rounded-xl p-4 shadow-sm border border-[var(--primary-200)] 
    hover:shadow-md transition-shadow duration-200;
  }

  /* Text Styles */
  .text-title {
    @apply text-[var(--text-100)] text-lg font-semibold;
  }

  .text-subtitle {
    @apply text-[var(--text-200)] text-sm;
  }

  /* Skill Tag Styles */
  .skill-tag {
    @apply inline-flex items-center rounded-full px-2 py-1 text-xs font-medium 
    bg-[var(--primary-100)] text-white hover:bg-[var(--primary-100)] hover:bg-opacity-90 
    transition-colors duration-200;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@layer base {
  ::-webkit-scrollbar-thumb{
  @apply bg-transparent shadow-sm
  }
  ::-webkit-scrollbar{
  @apply w-1 bg-transparent
  }
  ::-webkit-scrollbar-thumb{
  @apply rounded-none bg-gray-100 /*color trackbar*/
  }
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
      display: none;
  }
 /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
}
}

.slider {
  position: relative;
  width: 100%;
  margin: 0 auto;
}

.slider__track,
.slider__range,
.slider__left-value,
.slider__right-value {
  position: absolute;
}

.slider__track,
.slider__range {
  border-radius: 3px;
  height: 5px;
}

.slider__track {
  background-color: #ced4da;
  width: 100%;
  z-index: 1;
}

.slider__range {
  background-color: #4F46E5;
  z-index: 2;
}

.slider__left-value,
.slider__right-value {
  font-size: 12px;
  margin-top: 20px;
}

.slider__left-value {
  left: 6px;
}

.slider__right-value {
  right: -4px;
}

/* Removing the default appearance */
.thumb,
.thumb::-webkit-slider-thumb {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

.thumb {
  pointer-events: none;
  position: absolute;
  height: 0;
  width: 100%;
  outline: none;
}

.thumb--zindex-3 {
  z-index: 3;
}

.thumb--zindex-4 {
  z-index: 4;
}

.thumb--zindex-5 {
  z-index: 5;
}

/* For Chrome browsers */
.thumb::-webkit-slider-thumb {
  background-color: #f1f5f7;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px #ced4da;
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}

/* For Firefox browsers */
.thumb::-moz-range-thumb {
  background-color: #f1f5f7;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px #ced4da;
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.scrollbar-hide {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;     /* Firefox */
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;             /* Chrome, Safari and Opera */
}

.blur-background {
  filter: blur(1px);
  /* transition: filter 0.2s ease; */
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-slideDown {
  animation: slideDown 0.2s ease-out;
}

html, body, #root {
  height: 100%;
  overflow-y: auto;
  margin: 0;
  padding: 0;
}

@keyframes checkmark {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.animate-checkmark {
  animation: checkmark 0.5s ease-in-out forwards;
}

/* Common Component Classes */
.card-base {
  @apply bg-white rounded-lg border border-[var(--bg-200)] shadow-sm transition-all duration-200;
}

.section-padding {
  @apply px-4 py-6 sm:px-6 lg:px-8;
}

.gradient-bg {
  @apply bg-gradient-to-br from-[var(--primary-100)] to-[var(--primary-200)];
}

.input-base {
  @apply bg-white border border-[var(--bg-200)] rounded-md px-3 py-2 focus:border-[var(--primary-300)] focus:ring-1 focus:ring-[var(--primary-300)] outline-none transition-colors duration-200;
}

/* Add this new gradient class */
.mentor-card-gradient {
  background: linear-gradient(135deg, #4F46E5 0%, #7C3AED 100%);
  border-radius: 16px 16px 0 0;
  color: white;
}

.card-base {
  border-radius: 16px;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  transition: all 0.3s ease;
  border: 1px solid #E5E7EB;
  overflow: hidden;
}

.card-hover:hover {
  transform: translateY(-4px);
  box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
}

/* Update badge styles */
.badge-primary {
  background-color: rgba(255, 255, 255, 0.1);
  color: inherit;
  padding: 4px 12px;
  border-radius: 9999px;
  font-size: 0.875rem;
  font-weight: 500;
}

.badge-success {
  background-color: #E0F2FE;
  color: #0369A1;
  padding: 4px 12px;
  border-radius: 9999px;
  font-size: 0.875rem;
  font-weight: 500;
}